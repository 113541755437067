@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.mealPlansCard {
  width: 100%;
  @include mixins.flex_alignStart;
  flex-direction: column-reverse;
  gap: fn.rem(16);

  @media (width >=840px) {
    @include mixins.flex_centered;
    gap: fn.rem(32);
  }

  @media (width >=1024px) {
    @include mixins.flex_row;
    @include mixins.flex_centered;
  }

  @media (width >=1200px) {
    @include mixins.flex_alignStart;
    gap: 5%;
  }

  @media (width >=1500px) {
    @include mixins.flex_alignStart;
    gap: 7%;
  }

  .mealPlansCardBody,
  .mealPlanCardImage {
    width: 100%;

    @media (width >=1024px) {
      width: 50%;
    }
  }

  .mealPlansCardBody {
    @include mixins.flex_column;
    @include mixins.flex_alignStart;
    @include mixins.flex_justifyBetween;
    gap: fn.rem(16);

    @media (width >=1024px) {
      gap: fn.rem(20);
    }

    @media (width >=1200px) {
      padding-block: fn.rem(38);
    }

    .mealPlansCardCaption {
      @include mixins.flex_column;
      @include mixins.flex_alignStart;
      gap: fn.rem(16);

      @media (width >=1200px) {
        gap: fn.rem(32);
      }

      .cardTitle {
        color: colors.$base-text;
        font-weight: 600;
        font-size: clamp(fn.rem(18), 2vw + fn.rem(14), fn.rem(28));
        line-height: 110%;
      }

      .cardDescription {
        color: colors.$base-text;
        font-weight: 400;
        font-size: fn.rem(15);
        line-height: 1.5;

        @media (width >=1024px) {
          font-size: fn.rem(16);
        }
      }
    }

    .mealPlansCardDetails {
      @include mixins.flex_column;
      @include mixins.flex_alignStart;
      gap: fn.rem(10);

      @media (width >=1024px) {
        gap: fn.rem(4);
      }

      @media (width >=1200px) {
        gap: fn.rem(16);
      }

      .cardDetailContent {
        @include mixins.flex_alignStart;
        gap: fn.rem(10);

        .detailCaption {
          font-size: fn.rem(14);
          line-height: 160%;
          font-weight: 500;
          color: colors.$muted-4;

          @media (width >=1200px) {
            font-size: fn.rem(15);
          }
        }

        .detailDescription {
          // background: colors.$mint-bg;
          // color: colors.$mint;
          font-size: fn.rem(13);
          line-height: 160%;
          font-weight: 600;
          padding-inline: fn.rem(4);
          border-radius: var.$borderRadiusSm;

          @media (width >=1200px) {
            font-size: fn.rem(14);
          }
        }

        // .detailDescription {
        //   // font-size: fn.rem(13);
        //   // line-height: 160%;
        //   // font-weight: 600;
        //   // padding-inline: fn.rem(4);
        //   // border-radius: var.$borderRadiusSm;

        //   // @media (width >=1200px) {
        //   //   font-size: fn.rem(14);
        //   // }
        // }

        // .detailDescriptionDiscount {
        //   // background: colors.$danger-bg;
        //   // color: colors.$danger;
        //   // font-size: fn.rem(13);
        //   // line-height: 160%;
        //   // font-weight: 600;
        //   // padding-inline: fn.rem(4);
        //   // border-radius: var.$borderRadiusSm;

        //   // @media (width >=1200px) {
        //   //   font-size: fn.rem(14);
        //   // }
        // }
      }
    }

    .mealPlansCardFoter {
      width: 100%;
      @include mixins.flex_alignStart;
      flex-wrap: wrap;
      gap: fn.rem(16);
    }
  }

  .mealPlanCardImage {
    height: 300px;
    @include mixins.flex_centered;

    @media (width >=1024px) {
      height: 450px;
    }

    .cardImage {
      width: 100%;
      height: 100%;
    }
  }
}

@media (width >=1024px) {
  .reverseMealPlansCard {
    flex-direction: row-reverse;
  }
}
