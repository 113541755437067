@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;

.card {
  width: 100%;
  max-width: 300px;
  border-radius: var.$borderRadiusLg;
  padding-block: var.$paddingSm;
  padding-inline: var.$paddingSm;
  box-shadow: colors.$mint-alt 0px 0px 12px;
  transition: var.$transition;

  .cardImageContainer {
    width: 100%;
    border-radius: var.$borderRadiusLg;
    overflow: hidden;

    .cardImage {
      transform: scale(1);
      transition: var.$transition;
    }
  }

  &:hover {
    .cardImage {
      transform: scale(1.05);
    }
  }

  .flexCards {
    @include mixins.flex_column;
    @include mixins.flex_alignStart;
    gap: fn.rem(16);
  }

  .equalGrids {
    @include mixins.flex_column;
    @include mixins.flex_alignStart;
    grid-gap: fn.rem(16);
  }

  .cardContent {
    padding-block-start: var.$paddingSm;
    padding-block-end: var.$padding;

    .cardBody {
      @include mixins.flex_column;
      @include mixins.flex_alignStart;
      row-gap: fn.rem(10);

      .cardBadge {
        background: colors.$mint-alt;
        color: colors.$mint-dark;
        font-weight: 600;
        font-size: fn.rem(12);
        line-height: 140%;
        padding-block: fn.rem(3);
        padding-inline: fn.rem(10);
        border-radius: var.$borderRadiusSm;
      }

      .cardTitle {
        overflow: hidden;
        color: colors.$text-alt;
        text-transform: uppercase;
        font-weight: 700;
        font-size: fn.rem(18);
        line-height: 120%;
      }

      .cardDescription {
        color: colors.$text-alt;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: 140%;
      }

      .cardCaption {
        font-weight: 600;
        font-size: fn.rem(16);
        line-height: 140%;

        .startingAt {
          color: colors.$base-text-05;
        }
      }

      .brandAlt {
        color: colors.$mint;
      }

      .brandColor {
        color: colors.$brandColor;
      }
    }

    .cardFooter {
      padding-block-start: fn.rem(4);
      margin-bottom: auto;
    }
  }
}
