@use "../utils" as utils;
@use "./colors" as colors;
@use "./fonts" as fonts;
@use "../utils/functions" as fn;
@use "../utils/breakpoints" as screens;
@use "../utils/mixins" as mixins;

html {
  box-sizing: border-box;
  font-size: 100%;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  // -webkit-text-stroke-width: 0.01px;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100dvh;
  margin: 0;
  padding: 0;
  font-family: fonts.$base-font;
  background: colors.$base-bg;
  color: colors.$base-text;
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: none;

  /* Hide scrollbar for Chrome, Safari and Opera */
  body::-webkit-scrollbar {
    display: none;
  }

  header,
  footer,
  nav {
    width: 100%;
    height: 100%;
  }

  a,
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a,
  a:visited,
  a:active {
    cursor: pointer;
    letter-spacing: fn.rem(0.2);
    text-decoration: none;
  }

  button {
    outline: transparent;
    border: transparent;
  }

  ul {
    list-style: none;
    color: inherit;
    padding: 0;
    margin: 0;
  }

  li {
    letter-spacing: 0.5;
  }
}
