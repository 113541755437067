@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/breakpoints" as screens;
@use "../../../scss/global/fonts" as fonts;

.cartItemsSummary {
  width: 100%;
  padding-block: fn.rem(32);
  padding-inline: fn.rem(40);
  @include mixins.flex_column;
  @include mixins.flex_alignStart;
  gap: fn.rem(20);

  .cartItemsHeading {
    color: colors.$text-alt;
    font-weight: 600;
    font-size: fn.rem(30);
    line-height: 110%;
  }

  .cartForm {
    width: 100%;

    .paymentFormGroup {
      width: 100%;
      @include mixins.flex_column;
      padding-block-end: fn.rem(20);

      .paymentFormLabel {
        color: colors.$base-text;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: 140%;
        padding-block-end: fn.rem(4);
      }

      .paymentFormInput {
        background: colors.$alt-bg;
        color: colors.$base-text;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: fn.rem(18);
        padding-block: fn.rem(16);
        padding-inline: fn.rem(16);
        border: 1px solid colors.$inputBorder;
        border-radius: var.$borderRadiusSm;

        &:-moz-placeholder,
        &::-moz-placeholder,
        // &:-ms-input-placeholder,
        &::-webkit-input-placeholder,
        &:placeholder-shown,
        &:placeholder {
          color: colors.$base-text-muted;
        }

        &:focus-within {
          outline: 1px solid colors.$brandBorder;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      .paymentFormSelect {
        background: colors.$alt-bg;
        color: colors.$base-text;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: fn.rem(18);
        padding-block: fn.rem(14);
        padding-inline: fn.rem(16);
        border: 1px solid colors.$inputBorder;
        border-radius: var.$borderRadiusSm;

        .paymentFormOption {
          background: inherit;
          color: colors.$base-text;
          font-weight: 400;
          font-size: fn.rem(14);
          line-height: fn.rem(18);
          padding-block: fn.rem(14);
          padding-inline: fn.rem(16);
        }
      }

      .deliveryFee {
        background: colors.$alt-bg;
        color: colors.$base-text-07;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: fn.rem(18);
        padding-block: fn.rem(16);
        padding-inline: fn.rem(16);
        border: 1px solid colors.$inputBorder;
        border-radius: var.$borderRadiusSm;
      }

      .paymentFormSelectOption {
        font-family: fonts.$base-font;
        color: colors.$base-text-07;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-inline-end: fn.rem(20);
        background-position: right 50%;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);

        .paymentFormSelectOption {
          font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
            "Lucida Sans", Arial, sans-serif;
          background: colors.$alt-bg;
          color: colors.$base-text-07;
          font-weight: 400;
          font-size: fn.rem(16);
          line-height: fn.rem(18);
          padding-block: fn.rem(16);
          padding-inline: fn.rem(16);
          margin: 0;
        }
      }
    }

    .paymentFormGroupRecieverOrder {
      @include mixins.flex_row;
      @include mixins.flex_align;
      @include mixins.flex_justifyBetween;
    }

    .formTabsSwitch {
      width: 100%;

      .formTabsSwitchBtnContainer {
        width: 100%;
        background: colors.$biege-light;
        padding: fn.rem(6);
        border-radius: var.$borderRadiusSm;
        margin-block-start: fn.rem(5);
        margin-block-end: fn.rem(25);
        @include mixins.flex_align;
        @include mixins.flex_justifyBetween;

        .formTabsSwitchBtn {
          width: 50%;
          background: transparent;
          color: colors.$base-text-08;
          font-weight: 500;
          font-size: fn.rem(14);
          line-height: fn.rem(18);
          padding-block: fn.rem(10);
          border-radius: 5px;
          transition: var.$nav-transition;

          &.active {
            background: colors.$biege;
            color: colors.$brandColor;
          }
        }
      }

      .pickUpOrder,
      .delivery {
        display: none;
      }

      .activeTab {
        display: block;
      }
    }

    .cartSummary {
      width: 100%;
      @include mixins.flex_column;
      gap: fn.rem(12);
      padding-block-start: fn.rem(32);

      .cartMenuCost,
      .cartDeliveryFee,
      .cartTotalCost {
        width: 100%;
        @include mixins.flex_align;
        @include mixins.flex_justifyBetween;
      }

      .menuCostTitle,
      .deliveryFeeTitle,
      .totalCostTitle {
        color: colors.$mute-04;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: 140%;
      }

      .totalCostTitle {
        color: colors.$mute-7;
      }

      .menuCostPrice,
      .deliveryFeeCost,
      .totalCostPrice {
        color: colors.$base-text;
        font-weight: 600;
        font-size: fn.rem(16);
        line-height: 140%;
      }

      .cartBtnContainer {
        width: 100%;
        padding-block-start: fn.rem(40);
        padding-block-start: fn.rem(28);

        .cartBtn {
          width: 100%;
          background: colors.$brandColor;
          color: colors.$light;
          font-weight: 600;
          font-size: fn.rem(14);
          line-height: fn.rem(18);
          padding-block: fn.rem(14);
          border-radius: var.$borderRadiusRounded;
          transition: var.$transition;

          &:hover {
            background: colors.$mint;
          }
        }
      }
    }
  }
}
