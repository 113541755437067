@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;
@use "../../../scss/global/fonts" as fonts;

.singleOrderDetailsCard {
  width: 100%;
  padding: fn.rem(8);
  @include mixins.flex_align;
  @include mixins.flex_justifyBetween;
  box-shadow: none;
  border-radius: var.$borderRadius;
  transition: var.$transition;

  // &:hover {
  //   box-shadow: #F7FEF7 0px 50px 100px -20px, #F7FEF7 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  // }

  .cardImageContainer {
    width: fn.rem(100);
    @include mixins.flex_centered;
    border-radius: var.$borderRadiusSm;

    .singleOrderDetailsCardImage {
      width: 100%;
      aspect-ratio: 1/1;
    }
  }

  .singleOrderDetailsCardDetailsContainer {
    width: calc(100% - 85px);
    @include mixins.flex_align;
    @include mixins.flex_justifyBetween;
    padding-inline: fn.rem(20);

    .singleOrderDetailsCardDetails {
      width: 80%;
      @include mixins.flex_column;
      @include mixins.flex_alignStart;
      @include mixins.flex_justifyBetween;
      gap: fn.rem(4);

      .singleOrderDetailsCardTitle {
        color: colors.$text-alt;
        font-weight: 700;
        font-size: fn.rem(18);
        line-height: 120%;
        text-transform: uppercase;

        // SCREENS 768px and LARGER
        @include screens.breakpoint-down(sm) {
          font-size: fn.rem(16);
        }
      }

      .singleOrderDetailsCardDescription {
        color: colors.$text-alt;
        font-weight: 400;
        font-size: fn.rem(12);
        line-height: 140%;
      }

      .singleOrderDetailsCardQuantity {
        width: max-content;
        background: colors.$brandColor-light;
        color: colors.$brandColor;
        // margin-block-start: fn.rem(14);
        padding-block: fn.rem(2);
        padding-inline: fn.rem(8);
        font-weight: 500;
        font-size: fn.rem(12);
        line-height: 140%;
        border-radius: var.$borderRadiusXs;
      }
    }

    .singleOrderDetailsCardPriceContainer {
      width: 20%;
      margin-block-end: auto;

      .singleOrderDetailsCardPrice {
        width: 100%;
        color: colors.$mint;
        font-weight: 700;
        font-size: fn.rem(18);
        line-height: 140%;
        text-align: right;

        // SCREENS 768px and LARGER
        @include screens.breakpoint-down(sm) {
          font-size: fn.rem(16);
        }
      }
    }
  }
}

.reviewForm {
  font-family: fonts.$base-font;
}
