@use "./scss/style";
@use "./scss/global/colors" as colors;
@use "./scss/utils/breakpoints" as screens;
@import "./scss/global//fonts";

// TAILWIND CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

// REACT ELASTIC CAROUSEL
.rec-arrow-left,
.rec-arrow-right,
.rec-dot,
.rec-dot_active {
  display: none;
}

// .rec-carousel-item:focus-visible {
//   outline: none;
//   box-shadow: inset 0 0 1px 1px lightgrey;
// }

.rec-slider-container {
  margin-inline-start: 0 !important;
}
