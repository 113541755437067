@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.homeBlogCards {
  width: 100%;
  margin-block: fn.rem(60);

  .blogCardsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: fn.rem(20);
    padding-block: fn.rem(32);

    // SCREENS 1024px and LARGER
    @include screens.breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    // SCREENS 1024px and LARGER
    @include screens.breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
      gap: fn.rem(32);
    }

    .blogCardItem {
      width: 100%;
      background: colors.$brandColor-light;
      border-radius: 10px;
      padding: fn.rem(24);

      &:hover {
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        box-shadow:
          #f7fef7 0px 50px 100px -20px,
          #f7fef7 0px 30px 60px -30px,
          rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      }

      .blogCardBody {
        width: 100%;
        @include mixins.flex_column;
        @include mixins.flex_centered;
        gap: fn.rem(20);

        .overflowHidden {
          width: 100%;
          border-radius: fn.rem(10);
          overflow: hidden;

          .blogCardImage {
            width: inherit;
            aspect-ratio: 1/1;
            transform: scale(1);
            object-fit: cover;
            transition: var.$transition;
          }

          .blogCardImage:hover {
            transform: scale(1.2);
          }
        }

        .blogCardTitle {
          color: colors.$base-text;
          font-weight: 600;
          font-size: fn.rem(18);
          line-height: 120%;
          margin-inline: fn.rem(5);
        }

        .blogCardText {
          color: colors.$base-text;
          font-weight: 400;
          font-size: fn.rem(14);
          line-height: 160%;
          margin-inline: fn.rem(5);
        }

        .blogCardFooter {
          width: calc(100% - 10px);
          margin-inline: fn.rem(5);
          @include mixins.flex_align;
          @include mixins.flex_justifyBetween;

          .blogCardDate {
            color: colors.$mint;
            font-weight: 600;
            font-size: fn.rem(14);
            line-height: 160%;
          }

          .blogCardBtn {
            background: colors.$brandColor;
            color: colors.$light;
            border-radius: fn.rem(25);
            font-weight: 500;
            font-size: fn.rem(14);
            line-height: fn.rem(18);
            padding-block: fn.rem(8);
            padding-inline: fn.rem(16);
            @include mixins.flex_centered;
            gap: fn.rem(8);
            transition: var.$transition;

            .blogCardBtnIcon {
              transform: rotateY(0);
              font-size: fn.rem(20);
              transition: var.$transition;
            }

            &:hover {
              background: colors.$mint;

              .blogCardBtnIcon {
                transform: translateX(8px);
              }
            }
          }
        }
      }
    }
  }
}
