@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

/*=============================================
=                MAIN ORDER CARD              =
=============================================*/

.mainOrder {
  width: inherit;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr 1fr;
  gap: fn.rem(10);

  @include screens.breakpoint-up("md") {
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr;
    gap: fn.rem(32);
  }

  .mainOrderImage {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: var.$borderRadiusLg;
  }

  .mainOrderCaption {
    width: 100%;
    @include mixins.flex_column;
    gap: fn.rem(10);
    text-align: center;

    @include screens.breakpoint-up("md") {
      text-align: start;
    }

    .mainOrderTitle {
      color: colors.$text-alt;
      font-weight: 700;
      font-size: fn.rem(24);
      line-height: 120%;
      text-transform: uppercase;

      @include screens.breakpoint-up("md") {
        font-size: fn.rem(30);
      }
    }

    .mainOrderDescription {
      color: colors.$text-alt;
      font-weight: 400;
      font-size: fn.rem(14);
      line-height: 140%;
    }

    .mainOrderPrice {
      font-weight: 600;
      font-size: fn.rem(20);
      line-height: 140%;
      color: colors.$mint;
    }

    .quantityControl {
      width: 100%;
      padding-block: fn.rem(4);
      @include mixins.flex_centered;

      @include screens.breakpoint-up("md") {
        padding-block-start: fn.rem(20);
        padding-block-end: fn.rem(20);
        @include mixins.flex_align;
        @include mixins.flex_justifyStart;
      }

      .controlGroup {
        width: max-content;
        @include mixins.flex_align;
        border-radius: fn.rem(25);
        border: 2px solid colors.$brandBorder-alt;

        .quantityMinus {
          border-radius: fn.rem(16) 0 0 fn.rem(16);
        }
        .quantityPlus {
          border-radius: 0 fn.rem(16) fn.rem(16) 0;
        }

        .quantityPlus,
        .quantityMinus {
          background: colors.$brandColor-alt;
          transition: var.$nav-transition;

          & > svg {
            font-size: fn.rem(18);
            color: colors.$text-alt;
            transition: var.$nav-transition;
          }

          &:disabled {
            cursor: not-allowed;
            background: colors.$brandBorder;

            & > svg {
              color: colors.$light;
            }
          }
        }

        .quantityMinus,
        .quantityPlus,
        .quantity {
          width: fn.rem(40);
          height: fn.rem(35);
          color: colors.$base-text;
          @include mixins.flex_centered;
        }

        .quantity {
          background: colors.$light;
        }
      }
    }
  }
}

/*=====      End of MAIN ORDER CARD     ======*/
