.soFreshWay {
  display: grid;
  grid-template-areas: stack;
  align-items: center;
  justify-content: start;

  .stack {
    grid-area: stack;
  }
}
