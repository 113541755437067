@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

.orderStatusCard {
  width: 100%;
  padding-block: fn.rem(24);
  padding-inline: fn.rem(32);
  @include mixins.flex_align;
  @include mixins.flex_justifyBetween;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  @include screens.breakpoint-down(md) {
    // @include mixins.flex_column;
    // @include mixins.flex_alignStart;
    flex-wrap: wrap;
    gap: fn.rem(24);
  }

  .orderStatusInfo {
    @include mixins.flex_column;
    @include mixins.flex_alignStart;
    @include mixins.flex_justifyBetween;
    gap: fn.rem(16);

    .orderStatusDetails {
      @include mixins.flex_centered;
      gap: fn.rem(16);

      .orderStatusCaption {
        color: colors.$text-alt;
        font-weight: 600;
        font-size: fn.rem(20);
        line-height: 120%;
      }

      .orderStatusItem {
        color: colors.$text-alt-06;
        font-weight: 500;
        font-size: fn.rem(13);
        line-height: 150%;
        @include mixins.flex_alignStart;
        @include mixins.flex_justify;
        gap: fn.rem(8);

        @include screens.breakpoint-down(md) {
          @include mixins.flex_centered;
        }

        &:nth-of-type(2) {
          padding-inline: fn.rem(16);
          border-inline: 2px solid colors.$mute;

          @include screens.breakpoint-down(md) {
            padding-inline: fn.rem(8);
            @include mixins.flex_centered;
          }
        }

        .orderStatusIcon {
          width: fn.rem(20);
          aspect-ratio: 1/1;
        }
      }
    }
  }

  .orderPrice {
    color: colors.$mint;
    font-weight: 600;
    font-size: fn.rem(20);
    line-height: 140%;
  }

  .moreInfoBtnContainer {
    @include mixins.flex_alignEnd;
    gap: fn.rem(6);

    .moreInfoBtn {
      padding-block: fn.rem(14);
      padding-inline: fn.rem(28);
      font-weight: 600;
      font-size: fn.rem(14);
      line-height: fn.rem(18);
      border-radius: var.$borderRadiusRounded;
      @include mixins.flex_centered;
      transition: var.$transition;

      @include screens.breakpoint-down(md) {
        padding-block: fn.rem(10);
        padding-inline: fn.rem(12);
      }
    }

    .viewDetailsBtn {
      background: transparent;
      color: colors.$brandColor;
      border: 2px solid colors.$brandColor;
      transition: var.$transition;

      &:hover {
        background: colors.$brandColor;
        color: colors.$light;
        border: 2px solid transparent;
      }
    }

    .chatWithUsBtn {
      background: colors.$mint;
      color: colors.$light;
      border: 2px solid transparent;
      transition: var.$transition;

      &:hover {
        background: colors.$brandColor;
        color: colors.$light;
        border: 2px solid colors.$brandColor;
      }
    }
  }
}
