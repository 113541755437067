@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

.extrasCardsTitle {
  font-weight: 500;
  font-size: fn.rem(14);
  line-height: 140%;
  color: colors.$text-alt;
  padding-block-start: fn.rem(32);
  padding-block-end: fn.rem(16);
}

.extrasCardContainer {
  width: 100%;
  text-align: center;
  // @include mixins.flex_align;
  // @include mixins.flex_justifyAround;
  @include mixins.flex_centered;
  flex-wrap: wrap;
  // gap: fn.rem(24);
  gap: 10%;
}
