@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

.footerContainer {
  width: 100%;
  // margin-block-start: fn.rem(48);
  background: colors.$mint-alt;
  color: colors.$base-text;
  font-weight: 400;

  // // SCREENS 768px and LARGER
  // @include screens.breakpoint-up(md) {
  //   margin-block-start: fn.rem(60);
  // }
  // SCREENS 768px and LARGER
  @include screens.breakpoint-up(lg) {
    // margin-block-start: fn.rem(80);
    padding-block-start: fn.rem(66);
  }

  .footer {
    width: 100%;
    padding-block-start: fn.rem(66);
    padding-block-end: fn.rem(32);
    @include mixins.flex_column;
    @include mixins.flex_centered;
    gap: fn.rem(32);

    // SCREENS 768px and LARGER
    @include screens.breakpoint-up(lg) {
      padding-block-start: fn.rem(66);
      padding-block-end: fn.rem(48);
      @include mixins.flex_row;
      @include mixins.flex_alignStart;
    }

    .footerSocialsContainer {
      @include mixins.flex_column;
      @include mixins.flex_centered;
      gap: fn.rem(16);

      // SCREENS 768px and LARGER
      @include screens.breakpoint-down(lg) {
        order: 2;
      }

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(lg) {
        width: 40ch;
        @include mixins.flex_column;
        gap: fn.rem(21);
      }

      .footerCopyright {
        text-align: start;
      }

      .footerSocials {
        @include mixins.flex_centered;
        gap: fn.rem(16);

        // SCREENS 768px and LARGER
        @include screens.breakpoint-up(lg) {
          @include mixins.flex_align;
          justify-content: flex-start;
        }

        .footerSocialIcons {
          width: fn.rem(32);
          height: fn.rem(32);
          background: colors.$mint;
          color: colors.$light;
          border-radius: fn.rem(8);
          @include mixins.flex_centered;
          transition: var.$transition;

          &:hover {
            background: colors.$brandColor;
          }
        }
      }

      .footerSocials,
      .footerCopyright {
        width: 100%;
        @include mixins.flex_align;
      }
    }

    .footerLinksContainer {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: fn.rem(48);

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: unset;
        padding-bottom: fn.rem(32);
        // grid-template-rows: repeat(4, 1fr);
      }

      .footerCategory {
        margin-inline: auto;
        @include mixins.flex_column;
        @include mixins.flex_align; ////
        gap: fn.rem(10);
        // &:nth-last-of-type(1) {
        //   margin-inline: 2.2rem;
        //   place-items: center;
        // }

        // SCREENS 768px and LARGER
        @include screens.breakpoint-up(lg) {
          @include mixins.flex_alignStart;
        }

        .footerCategoryTitle {
          color: colors.$base-text;
          font-weight: 600;
          font-size: fn.rem(16);
          line-height: 160%;
        }

        .footerLinks {
          @include mixins.flex_column;
          @include mixins.flex_alignStart;
          gap: fn.rem(10);

          .footerLinkItem {
            color: colors.$base-text;
            font-size: fn.rem(14);
            line-height: 160%;
            transition: var.$transition;

            &:hover {
              color: colors.$brandColor;
            }
          }
        }
      }
    }
  }
}
