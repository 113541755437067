@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin flex {
  display: flex;
}

@mixin flex_column {
  display: flex;
  flex-direction: column;
}

@mixin flex_row {
  display: flex;
  flex-direction: row;
}

@mixin flex_centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex_justify {
  display: flex;
  justify-content: center;
}

@mixin flex_justifyBetween {
  display: flex;
  justify-content: space-between;
}

@mixin flex_justifyStart {
  display: flex;
  justify-content: flex-start;
}

@mixin flex_justifyEnd {
  display: flex;
  justify-content: flex-end;
}

@mixin flex_justifyAround {
  display: flex;
  justify-content: space-around;
}

@mixin flex_align {
  display: flex;
  align-items: center;
}

@mixin flex_alignEnd {
  display: flex;
  align-items: flex-end;
}

@mixin flex_alignStart {
  display: flex;
  align-items: flex-start;
}
