@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.homeSubscription {
  width: 100%;
  background: colors.$alt-bg;
  padding-block: fn.rem(83);

  .homeSubscriptionContainer {
    width: 100%;
    @include mixins.flex_column;
    @include mixins.flex_centered;
    gap: 10%;

    // SCREENS 768px and LARGER
    @include screens.breakpoint-up(md) {
      @include mixins.flex_row;
      @include mixins.flex_justifyBetween;
    }

    .subscription,
    .download {
      width: 100%;
      text-align: center;
      padding-block: fn.rem(32);
      @include mixins.flex_column;
      @include mixins.flex_centered;
      gap: fn.rem(16);

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(md) {
        width: 50%;
        text-align: start;
        @include mixins.flex_column;
        @include mixins.flex_alignStart;
        gap: fn.rem(36);
      }

      .subscriptionTitle,
      .downloadTitle {
        color: colors.$brandColor;
        font-weight: 500;
        font-size: fn.rem(16);
        line-height: 140%;

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          font-weight: 600;
          font-size: fn.rem(20);
        }
      }

      .subscriptionText,
      .downloadText {
        color: colors.$base-text;
        font-weight: 500;
        font-size: fn.rem(16);
        line-height: 140%;
        max-width: 30ch;

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          font-size: fn.rem(24);
        }
      }

      .subscriptionForm,
      .downloadLinks {
        // SCREENS 768px and LARGER
        @include screens.breakpoint-down(md) {
          padding-block-start: fn.rem(32);
        }
      }

      .subscriptionForm {
        width: 100%;

        .subscriptionLabel {
          width: 100%;
          background: colors.$brandColor-light;
          padding: fn.rem(8);
          border-radius: 5px;
          border: 1px solid colors.$brandBorder;
          @include mixins.flex_align;
          @include mixins.flex_justifyBetween;

          // SCREENS 1025px and LARGER
          @include screens.breakpoint-up(lg) {
            padding: fn.rem(14);
          }

          .subscriptionInput {
            width: 72%;
            caret-color: colors.$brandColor;
            background: colors.$brandColor-light;
            color: colors.$base-text;
            outline: transparent;
            border: transparent;
            transition: var.$transition;

            &:focus,
            &:focus-visible,
            &:focus-within {
              outline: transparent;
              border: transparent;
            }

            &:placeholder-shown,
            &::placeholder {
              color: colors.$brandBorder;
            }
          }

          .subscriptionBtn {
            width: 25%;
            background: colors.$brandColor;
            color: colors.$light;
            font-weight: 500;
            font-size: fn.rem(14);
            line-height: fn.rem(15);
            padding-block: fn.rem(12);
            padding-inline: fn.rem(16);
            border-radius: 25px;
            transition: var.$transition;

            &:hover {
              background: colors.$mint;
            }
          }
        }
      }

      .downloadLinks {
        width: 100%;
        @include mixins.flex_centered;
        gap: fn.rem(16);

        // SCREENS 768px and LARGER
        @include screens.breakpoint-up(md) {
          @include mixins.flex_row;
          @include mixins.flex_align;
          @include mixins.flex_justifyStart;
        }

        .downloadLink {
          display: flex;
          filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.06));
        }
      }
    }
  }
}
