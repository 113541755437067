@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.mealPlansHero {
  width: 100%;
  @include mixins.flex_centered;
  @include mixins.flex_column;

  @media (width >=1025px) {
    @include mixins.flex_centered;
    @include mixins.flex_row;
  }

  .mealPlansHeroCaption {
    width: 90%;
    background: colors.$light;
    margin-block-start: -7%;
    padding-block: fn.rem(28);
    padding-inline: fn.rem(32);
    @include mixins.flex_column;
    @include mixins.flex_centered;
    gap: fn.rem(20);
    order: 2;
    border-radius: var.$borderRadius;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    @media (width >=725px) {
      width: 80%;
      padding-block: fn.rem(40);
      padding-inline: fn.rem(40);
    }

    @media (width >=1025px) {
      width: 60%;
      margin-block-end: fn.rem(48);
      margin-block-start: unset;
      padding-block: fn.rem(40);
      padding-inline-end: fn.rem(32);
      @include mixins.flex_alignStart;
      @include mixins.flex_justify;
    }

    @media (width >=1600px) {
      margin-block: unset;
    }

    .mealPlansHeroTitle {
      color: colors.$base-text;
      font-weight: 700;
      font-size: clamp(fn.rem(32), 4vw + fn.rem(16), fn.rem(48));
      line-height: 1;
    }

    .mealPlansHeroTextContainer {
      width: 100%;
      color: colors.$base-text;
      font-weight: 400;
      font-size: fn.rem(15);
      line-height: 150%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;

      @media (width <=1024px) {
        margin-block-end: fn.rem(20);
      }

      @media (width <=768px) {
        margin-block-end: fn.rem(10);
      }

      .heroTextTruncate {
        @media (width <=1024px) {
          display: none;
        }
      }

      // .mealPlansHeroTextContainer {
      //   overflow-wrap: break-word;
      //   word-wrap: break-word;
      //   hyphens: auto;
      // }
    }

    .plansHeroBtn {
      background: colors.$brandColor;
      color: colors.$light;
      font-weight: 500;
      font-size: fn.rem(16);
      line-height: fn.rem(18);
      margin-block: fn.rem(8);
      padding-block: fn.rem(14);
      padding-inline: fn.rem(28);
      border-radius: var.$borderRadiusRounded;
      transition: var.$transition;

      &:hover {
        background: colors.$mint;
      }
    }
  }

  .mealPlansHeroBanner {
    width: 100%;
    height: fn.rem(250);
    // background: url('../../../../assests/images/MealPlan/mealplan_hero.webp') no-repeat center / cover;
    z-index: -1;

    @media (width >=725px) {
      height: fn.rem(500);
    }

    @media (width >=1025px) {
      width: 60%;
      margin-inline-start: -7%;
      order: 2;
    }

    @media (width >=1600px) {
      width: 85%;
      height: fn.rem(670);
      margin-inline-start: -10%;
      order: 2;
    }

    .mealPlansHeroBannerImage {
      width: 100%;
      height: inherit;
      @include mixins.flex_centered;
    }
  }
}
