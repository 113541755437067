@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.mealPlansSection {
  width: 100%;
  padding-block: fn.rem(32);

  @media (width >=768px) {
    padding-block: fn.rem(60);
  }

  .mealPlansSectionTitle {
    color: colors.$base-text;
    font-weight: 600;
    font-size: clamp(fn.rem(28), 3.5vw + fn.rem(16), fn.rem(40));
    line-height: fn.rem(50);
    padding-block-start: fn.rem(32);
    padding-block-end: fn.rem(40);
    text-align: center;

    @media (width >=768px) {
      padding-block-start: fn.rem(48);
      padding-block-end: fn.rem(48);
    }
  }

  .mealPlansCardsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: fn.rem(60);

    @media (width <=500px) {
      padding-inline: fn.rem(32);
    }

    @media (width >=768px) {
      grid-template-columns: 1fr;
      align-items: start;
    }

    @media (width >=1024px) {
      grid-template-columns: 1fr;
      gap: fn.rem(80);
    }

    @media (width >=1200px) {
      gap: fn.rem(120);
    }
  }
}
