@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;

.categoriesCarousel {
  width: 100%;

  .carouselOuter {
    width: 100%;
    @include mixins.flex_centered;
    gap: fn.rem(8);

    .carouselControls {
      background: colors.$brandColor-alt;
      color: colors.$brandColor;
      font-size: fn.rem(18);
      padding: 0.2rem;
      border: 3px solid colors.$brandColor;
      border-radius: 50%;
      transform: translateY(-2rem);
      transition: var.$transition;

      &:hover {
        background: colors.$brandColor;
        color: colors.$light;
      }
    }

    .carouselInner {
      width: 100%;
      @include mixins.flex_align;
      gap: fn.rem(16);
      padding-block-end: fn.rem(16);
      /* Hide scrollbar for IE, Edge and Firefox */
      overflow-x: hidden;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
