@use "../../scss/global/colors" as colors;
@use "../../scss/utils/mixins" as mixins;
@use "../../scss/utils/functions" as fn;
@use "../../scss/utils/variables" as var;
@use "../../scss/utils/breakpoints" as screens;

.cartPage {
  width: 100%;

  .cart {
    width: 100%;
    min-height: 65dvh;
    padding-block: fn.rem(48);
    @include mixins.flex_column;
    @include mixins.flex_align;

    @include screens.breakpoint-up(md) {
      padding-block: fn.rem(60);
    }

    .cartTitle {
      width: 100%;
      font-weight: 600;
      font-size: fn.rem(32);
      line-height: 110%;
      color: colors.$base-text;

      @include screens.breakpoint-up(md) {
        font-size: fn.rem(40);
      }
    }

    .cartContent {
      width: 100%;
      padding-block: fn.rem(48);
      display: grid;
      gap: fn.rem(32);
      // grid-template-columns: 1fr;
      // grid-template-rows: repeat(2, 1fr);

      @include screens.breakpoint-up(md) {
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr;
        gap: fn.rem(40);
      }

      .cartItemsContainer {
        background: colors.$base-bg;
        @include mixins.flex_column;
        @include mixins.flex_align;
        gap: fn.rem(24);
      }

      .cartItemsSummaryContainer {
        background: colors.$alt-bg;
      }
    }
  }
}
