// FONT FAMILIES
$base-font: "BR-Firma";

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma Thin"),
    local("../../assests/fonts/BR-Firma-Thin"),
    url("../../assests/fonts/BRFirma-Thin/BRFirma-Thin.woff2") format("woff2"),
    url("../../assests/fonts/BRFirma-Thin/BRFirma-Thin.woff") format("woff"),
    url("../../assests/fonts/BRFirma-Thin/BRFirma-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma Extra Light"),
    local("../../assests/fonts/BRFirma-ExtraLight"),
    url("../../assests/fonts/BRFirma-ExtraLight/BRFirma-ExtraLight.woff2")
      format("woff2"),
    url("../../assests/fonts/BRFirma-ExtraLight/BRFirma-ExtraLight.woff")
      format("woff"),
    url("../../assests/fonts/BRFirma-ExtraLight/BRFirma-ExtraLight.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma Light"),
    local("../../assests/fonts/BRFirma-Light"),
    url("../../assests/fonts//BRFirma-Light/BRFirma-Light.woff2")
      format("woff2"),
    url("../../assests/fonts/BRFirma-Light/BRFirma-Light.woff") format("woff"),
    url("../../assests/fonts/BRFirma-Light/BRFirma-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma Regular"),
    local("../../assests/fonts/BR-Firma-Regular"),
    url("../../assests/fonts/BRFirma-Regular/BRFirma-Regular.woff2")
      format("woff2"),
    url("../../assests/fonts/BRFirma-Regular/BRFirma-Regular.woff")
      format("woff"),
    url("../../assests/fonts/BRFirma-Regular/BRFirma-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma Medium"),
    local("../../assests/fonts/BRFirma-Medium"),
    url("../../assests/fonts/BRFirma-Medium/BRFirma-Medium.woff2")
      format("woff2"),
    url("../../assests/fonts/BRFirma-Medium/BRFirma-Medium.woff") format("woff"),
    url("../../assests/fonts/BRFirma-Medium/BRFirma-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma SemiBold"),
    local("../../assests/fonts/BRFirma-SemiBold"),
    url("../../assests/fonts/BRFirma-SemiBold/BRFirma-SemiBold.woff2")
      format("woff2"),
    url("../../assests/fonts/BRFirma-SemiBold/BRFirma-SemiBold.woff")
      format("woff"),
    url("../../assests/fonts/BRFirma-SemiBold/BRFirma-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma Bold"),
    local("../../assests/fonts/BRFirma-Bold"),
    url("../../assests/fonts/BRFirma-Bold/BRFirma-Bold.woff2") format("woff2"),
    url("../../assests/fonts/BRFirma-Bold/BRFirma-Bold.woff") format("woff"),
    url("../../assests/fonts/BRFirma-Bold/BRFirma-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "BR-Firma";
  src:
    local("BR-Firma Black"),
    local("../../assests/fonts/BRFirma-Black"),
    url("../../assests/fonts/BRFirma-Black/BRFirma-Black.woff2") format("woff2"),
    url("../../assests/fonts/BRFirma-Black/BRFirma-Black.woff") format("woff"),
    url("../../assests/fonts/BRFirma-Black/BRFirma-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
}
