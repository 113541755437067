@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/breakpoints" as screens;

.superComboBanner {
  width: 100%;
  background-image: url("../../../../assests/images/HomePage/BannerOverlay.webp");
  background-size: cover;
  background-color: colors.$mint-alt;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;

  .superComboContainer {
    width: 100%;
    // padding-block: fn.rem(20);
    @include mixins.flex_align;
    @include mixins.flex_column;

    // SCREENS 768px and LARGER
    @include screens.breakpoint-up(md) {
      @include mixins.flex_row;
      gap: fn.rem(20);
    }

    .superComboArticle,
    .superComboImageContainer {
      width: 100%;
    }

    .superComboArticle {
      text-align: center;
      // padding-block: fn.rem(18);
      padding-block: fn.rem(38);
      @include mixins.flex_column;
      gap: fn.rem(20);

      // SCREENS 456px and SMALLER
      @include screens.breakpoint-down(sm) {
        order: 2;
      }

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(md) {
        padding-inline-start: fn.rem(50);
        text-align: left;
      }

      // SCREENS 1025px and LARGER
      @include screens.breakpoint-up(lg) {
        padding-inline-start: 0;
      }

      .superComboArticleTitle {
        color: colors.$base-text;
        font-weight: 600;
        font-size: fn.rem(24);
        line-height: fn.rem(34);

        // SCREENS 768px and LARGER
        @include screens.breakpoint-up(md) {
          font-size: fn.rem(32);
          line-height: fn.rem(42);
        }

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          font-size: fn.rem(40);
          line-height: fn.rem(50);
        }
      }

      .superComboArticleText,
      .superComboArticleSubText {
        color: colors.$base-text;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: 160%;

        // SCREENS 768px and LARGER
        @include screens.breakpoint-up(md) {
          font-size: fn.rem(16);
        }

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          font-size: fn.rem(18);
        }
      }

      .superComboArticleSubText {
        text-transform: uppercase;
        color: colors.$mint;
        padding-bottom: fn.rem(20);

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          padding-bottom: fn.rem(45);
        }
      }

      .btnContainer {
        // SCREENS 456px and SMALLER
        @include screens.breakpoint-down(sm) {
          margin-inline: auto;
        }
      }

      .superComboImageContainer {
        border: 2px solid red;

        .superComboImage {
          width: 100%;
          aspect-ratio: 7/5;
        }
      }
    }
  }
}
