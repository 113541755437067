// COLOR VARIABLES
$base-bg: #ffffff;
$alt-bg: #fffaf4;
$base-text: #041d05;
$base-text-02: #041d0533;
$base-text-05: #041d0580;
$base-text-08: #041d05cc;
$base-text-07: #041d05b3;
$base-text-muted: #051c0633;
$text-alt: #374935;
$text-alt-06: #37493599;
$light: #ffffff;
$brandColor: #fd9f28;
$brandColor-bg: #ffefdd;
$brandColor-divider: rgba(251, 206, 156, 0.5);
$brandColor-50: #fd9f2880;
$brandColor-light: #fff2e2;
$brandColor-alt: #fd9f2833;
$brandColor-mid: #fd9f284d;
$brandBorder: #e2d2bf;
$brandBorder-alt: #f9efe4;
$inputBorder: #f1e7da;
$biege: #ffe1bc;
$biege-light: #fff1df;
$danger-bg: #df8080;
$danger-dark: #ff0000;
$danger: #dc3545;
$burn: #fd9000;
$burn-alt: #ffedd6;
$mint: #339e35;
$mint-mild: #deffde;
$mint-01: #339e351a;
$mint-dropDown: #d0f3d0;
$mint-06: #339e35a6;
$mint-dark: #006838;
$mint-bg2: #ebfeeb;
$mint-bg: #ebf3eb;
$mint-alt: #ebf5eb;
$mint-light: #f7fef7;
$mute: #87928666;
$mute-007: #041d0512;
$mute-04: #041d0570;
$mute-7: #041d05b3;
$mute-1: #041d0524;
$muted-4: #041d0566;
$mute-5: #041d0580;
$nav-blur: #fafafae6;
$glass: #ffffff1a;
$btnInactive: #ebebeb;
$linearGradient: linear-gradient(90.08deg, $mint 17.16%, $mint-alt 99.95%);
$linear-gradient-two: linear-gradient(
  90.08deg,
  rgba(14, 95, 15, 0.7) 17.16%,
  rgba(253, 159, 40, 0.21) 99.95%
);

// LOADING ANIMATION
$loading-animation: linear-gradient(
  90deg,
  #ebebeb 8%,
  #dedede 20%,
  #ebebeb 33%
);
