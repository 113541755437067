@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.homeAboutSection {
  width: 100%;
  // height: max-content;
  background: colors.$brandColor;
  background-image: url("../../../../assests/images/HomePage/aboutBannerBg.webp");
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
  object-fit: contain;

  .homeAboutSection {
    width: 100%;
    @include mixins.flex_column;
    // @include mixins.flex_align;
    // @include mixins.flex_justifyBetween;
    @include mixins.flex_centered;
    gap: fn.rem(36);

    // SCREENS 768px and LARGER
    @include screens.breakpoint-up(md) {
      @include mixins.flex_row;
      height: 100%;
    }

    // // SCREENS 768px and LARGER
    // @include screens.breakpoint-up(lg) {
    //   gap: fn.rem(135);
    // }

    .aboutSectionCaptionContainer,
    .aboutSectionImageContainer {
      width: 100%;
      height: max-content;

      // SCREENS 1024px and LARGER
      @include screens.breakpoint-up(lg) {
        width: 50%;
        height: max-content;
      }
    }

    .aboutSectionCaptionContainer {
      // SCREENS 425px and SMALLER
      @include screens.breakpoint-down(sm) {
        order: 1;
        padding-block-end: fn.rem(40);
        text-align: center;
      }

      // SCREENS 425px and SMALLER
      @include screens.breakpoint-up(md) {
        // padding-inline-end: 0;
        // padding-inline-start: 60px;
        max-width: 50ch;
      }

      .aboutSectionCaption {
        height: inherit;
        @include mixins.flex_column;
        @include mixins.flex_alignStart;
        @include mixins.flex_justifyAround;

        // SCREENS 768px and LARGER
        @include screens.breakpoint-down(sm) {
          @include mixins.flex_align;
        }

        .aboutSectionCaptionTitle {
          color: colors.$light;
          font-weight: 600;
          font-size: fn.rem(28);
          line-height: 1.5;
          padding-block-end: fn.rem(16);

          // SCREENS 768px and LARGER
          @include screens.breakpoint-up(md) {
            font-size: fn.rem(32);
            line-height: 1.5;
            padding-block-start: unset;
          }

          // SCREENS 1024px and LARGER
          @include screens.breakpoint-up(lg) {
            font-size: fn.rem(40);
            line-height: fn.rem(50);
            padding-block-end: fn.rem(24);
          }
        }

        .aboutSectionCaptionText {
          color: colors.$light;
          font-weight: 400;
          font-size: fn.rem(14);
          line-height: 160%;
          padding-block-end: fn.rem(32);

          // SCREENS 1024px and LARGER
          @include screens.breakpoint-up(lg) {
            font-size: fn.rem(18);
            line-height: 160%;
          }
        }
      }
    }
  }

  .displaySmNone {
    // SCREENS 768px and SMALLER
    @include screens.breakpoint-down(md) {
      display: none;
    }
  }
}
