@use "../../scss/utils/mixins" as mixins;
// @use '../../scss/global/colors' as colors;
// @use '../../scss/utils/functions' as fn;
// @use '../../scss/utils/variables' as var;
// @use '../../scss/utils/breakpoints' as screens;

.mealPlans {
  width: 100%;
  height: 100%;
  padding-block: 5%;

  .modalLoader {
    width: 100%;
    padding-block: 10%;
    @include mixins.flex_centered;
  }
}
