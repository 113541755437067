@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/breakpoints" as screens;
@use "../../../scss/global/fonts" as fonts;

.placesContainer {
  width: 100%;
  background: inherit;
  @include mixins.flex_column;
  @include mixins.flex_alignStart;
  @include mixins.flex_justify;

  .placesLabel {
    color: colors.$base-text;
    font-weight: 400;
    font-size: fn.rem(14);
    line-height: 140%;
    padding-block-end: fn.rem(4);
  }

  .placesInput {
    width: 100%;
    background: inherit;
    color: colors.$base-text;
    font-weight: 400;
    font-size: fn.rem(14);
    line-height: fn.rem(18);
    padding-block: fn.rem(16);
    padding-inline: fn.rem(16);
    border: 1px solid colors.$inputBorder;
    border-radius: var.$borderRadiusSm;
    transition: var.$nav-transition;

    &:-moz-placeholder,
    &::-moz-placeholder,
    // &:-ms-input-placeholder,
    &::-webkit-input-placeholder,
    &:placeholder-shown,
    &:placeholder {
      color: colors.$base-text-muted;
    }

    &:focus-within {
      outline: 1px solid colors.$brandBorder;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .placesList {
    color: colors.$base-text-08;
    font-weight: 400;
    font-size: fn.rem(14);
    line-height: fn.rem(18);
    margin-block-end: fn.rem(16);
    border: 1px solid colors.$inputBorder;
    border-radius: var.$borderRadiusSm;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    .placesListItem {
      cursor: pointer;
      background: inherit;
      padding-block: fn.rem(4);
      padding-inline: fn.rem(16);
      border: 1px solid colors.$inputBorder;
      transition: var.$nav-transition;

      &:hover {
        background: colors.$biege-light;
      }
    }
  }
}
