@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

.btn {
  cursor: pointer;
  width: fit-content;
  font-weight: 500;
  font-size: fn.rem(14);
  padding-block-start: fn.rem(6);
  padding-block-end: fn.rem(8);
  padding-inline: fn.rem(20);
  border-radius: fn.rem(25);
  transition: var.$transition;

  // SCREENS 768px and LARGER
  @include screens.breakpoint-up(md) {
    font-size: fn.rem(16);
    padding-block: fn.rem(10);
    padding-inline: fn.rem(30);
  }
}

.smallPaddingInline {
  padding-inline: fn.rem(10);
}

.lightBtn {
  background-color: colors.$light;
  color: colors.$brandColor;
  border: 1px solid colors.$brandColor;
  transition: var.$transition;

  &:hover {
    background-color: colors.$mint;
    color: colors.$light;
    border: 1px solid colors.$mint;
  }
}

.solidBtn {
  background-color: colors.$brandColor;
  color: colors.$light;
  border: 1px solid colors.$brandColor;
  transition: var.$transition;

  &:hover {
    background-color: colors.$mint;
    color: colors.$alt-bg;
    border: 1px solid colors.$mint;
  }
}

.fullWidth {
  // SCREENS 765px and LOWER
  @include screens.breakpoint-down(sm) {
    width: 100%;
  }
}

.greenBtn {
  background-color: #339e35;
  color: colors.$light;
  border: 1px solid #339e35;
  transition: var.$transition;

  &:hover {
    background-color: #fd9f28;
    color: #fff;
    border: 1px solid #fd9f28;
  }
}

.brownBtn {
  background-color: #3e3131;
  color: colors.$light;
  border: 1px solid #3e3131;
  transition: var.$transition;

  &:hover {
    background-color: #3e3131;
    color: #fff;
    border: 1px solid #3e3131;
  }
}
