@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/breakpoints" as screens;

.cartItemCard {
  width: 100%;
  @include mixins.flex_align;
  gap: fn.rem(8);
  padding-block-end: fn.rem(10);
  box-shadow: colors.$mint-bg 0px 2px 2px 0px;
  transition: var.$nav-transition;

  .removeFromCartBtn {
    height: fn.rem(140);
    background: colors.$mint-bg;
    border-radius: var.$borderRadiusSm;
    padding-inline: fn.rem(4);
    @include mixins.flex_centered;
    box-shadow: none;
    transition: var.$nav-transition;

    // &:hover {
    //   box-shadow: #F7FEF7 0px 50px 100px -20px, #F7FEF7 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    // }

    .removeItemIcon {
      cursor: pointer;
      font-size: fn.rem(30);
      transition: var.$nav-transition;

      &:hover {
        color: colors.$danger;
      }
    }
  }

  .cartItem {
    width: 100%;
    // @include mixins.flex_alignStart;
    @include mixins.flex_align;
    border-radius: var.$borderRadius;
    box-shadow: none;
    transition: var.$nav-transition;

    // &:hover {
    //   box-shadow: #F7FEF7 0px 50px 100px -20px, #F7FEF7 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    // }

    .cartItemImage {
      width: fn.rem(140);
      aspect-ratio: 1/1;
      border-radius: var.$borderRadiusSm;
    }

    .cartItemDetails {
      padding-block: fn.rem(8);
      padding-inline: fn.rem(14);
      @include mixins.flex_column;
      @include mixins.flex_alignStart;
      gap: fn.rem(4);

      .cartItemName {
        color: colors.$text-alt;
        font-weight: 700;
        font-size: fn.rem(18);
        line-height: 120%;
        text-transform: uppercase;
      }

      .cartItemExtras {
        color: colors.$text-alt;
        font-weight: 400;
        font-size: fn.rem(14);
        line-height: 120%;
        @include mixins.flex_alignStart;
        flex-wrap: wrap;
        gap: fn.rem(4);

        .cartItemExtra:after {
          content: ",";
        }

        .cartItemExtra:last-of-type:after {
          content: "";
        }
      }

      .cartItemPrice {
        color: colors.$mint;
        font-weight: 600;
        font-size: fn.rem(20);
        line-height: 140%;
        padding-block-start: fn.rem(8);
      }

      .cartItemDownloadLink {
        color: colors.$brandColor;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        text-decoration-line: underline;
        padding-block-start: fn.rem(18);
        transition: var.$transition;

        &:hover {
          color: colors.$mint;
          text-decoration-line: none;
        }

        @include screens.breakpoint-up(md) {
          font-size: 14px;
          line-height: 120%;
          padding-block-start: fn.rem(24);
        }
      }
    }
  }
}
