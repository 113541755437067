@use "../../scss/global/colors" as colors;
@use "../../scss/utils/mixins" as mixins;
@use "../../scss/utils/functions" as fn;
@use "../../scss/utils/variables" as var;
@use "../../scss/utils/breakpoints" as screens;

.singleMenu {
  width: 100%;
  padding-block: fn.rem(32);

  @include screens.breakpoint-up("md") {
    padding-block: fn.rem(60);
  }

  .loader {
    min-height: inherit;
    @include mixins.flex_centered;
  }

  .singleMenuCard {
    width: 100%;
    min-height: inherit;
    @include mixins.flex_column;
    // outline: 2px solid red;

    @include screens.breakpoint-up("md") {
      @include mixins.flex_row;
    }

    .cardImage,
    .cardContent {
      width: 100%;
      @include mixins.flex_align;
    }

    .cardImage {
      max-width: 350px;
      aspect-ratio: 1/1;
    }

    .cardContent {
      width: 100%;
      @include mixins.flex_alignStart;
      // outline: 2px solid green;

      .cardBody {
        width: 100%;
        padding-block: 5%;
        @include mixins.flex_column;
        @include mixins.flex_align;
        gap: fn.rem(4);

        @include screens.breakpoint-up("md") {
          padding-block: 10%;
          @include mixins.flex_alignStart;
          gap: fn.rem(10);
        }

        .cardTitle {
          color: colors.$base-text;
          font-size: fn.rem(24);
          font-weight: 600;
          line-height: fn.rem(42);
          text-transform: uppercase;
          text-align: center;

          @include screens.breakpoint-up("md") {
            font-size: fn.rem(30);
            text-align: start;
          }
        }

        .cardCaption {
          color: colors.$mint;
          font-size: fn.rem(18);
          font-weight: 600;
          line-height: fn.rem(24);
        }

        .cardDescription {
          color: colors.$base-text;
          font-size: fn.rem(16);
          font-weight: 400;
          line-height: fn.rem(24);
        }

        .quantityControl {
          padding-block-start: fn.rem(16);
          padding-block-end: fn.rem(32);

          @include screens.breakpoint-up("md") {
            padding-block-start: fn.rem(20);
            padding-block-end: fn.rem(48);
          }

          .controlGroup {
            @include mixins.flex_align;
            border-radius: fn.rem(25);
            border: 2px solid colors.$brandBorder-alt;

            .quantityMinus {
              border-radius: fn.rem(16) 0 0 fn.rem(16);
            }
            .quantityPlus {
              border-radius: 0 fn.rem(16) fn.rem(16) 0;
            }

            .quantityPlus,
            .quantityMinus {
              background: colors.$brandColor-alt;
              transition: var.$nav-transition;

              & > svg {
                font-size: fn.rem(18);
                color: colors.$text-alt;
                transition: var.$nav-transition;
              }

              &:disabled {
                cursor: not-allowed;
                background: colors.$brandBorder;

                & > svg {
                  color: colors.$light;
                }
              }
            }

            .quantityMinus,
            .quantityPlus,
            .quantity {
              width: fn.rem(40);
              height: fn.rem(35);
              color: colors.$base-text;
              @include mixins.flex_centered;
            }

            .quantity {
              background: colors.$light;
            }
          }
        }
      }

      .orderNowBtn {
        background: colors.$brandColor;
        color: colors.$light;
        padding-block: fn.rem(14);
        padding-inline: fn.rem(32);
        margin-block-start: fn.rem(32);
        margin-block-end: fn.rem(24);
        border-radius: fn.rem(25);
        transition: var.$transition;

        &:hover {
          background: colors.$mint;
        }
      }
    }
  }
}
