@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;

/*==============================================
  =            MODAL SECTION                   =
  =============================================*/

.modalOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-block: fn.rem(80);
  z-index: 2;

  .modal {
    position: relative;
    background-color: colors.$base-bg;
    // width: 90%;
    // max-width: fn.rem(500);
    padding-block: fn.rem(32);
    padding-inline: fn.rem(32);
    border-radius: var.$borderRadiusRounded;
    transition: all 300ms ease-in;

    .modalHeader {
      font-family: "BR Firma";
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: fn.rem(10);
      padding-bottom: fn.rem(10);

      .modalHeaderTop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modalTitle {
          max-width: 30ch;

          .modalHeaderTitle {
            font-weight: 600;
            font-size: fn.rem(24);
            line-height: 120%;
            text-transform: capitalize;
            color: #374935;
          }
        }

        .modalCloseBtn {
          width: fn.rem(26);
          height: fn.rem(26);
          background: #ffffff;
          color: #374935;
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: transparent;
          border: transparent;
          border-radius: 50%;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12);
          transition: all 300ms ease-in;

          &:hover {
            background: #f5f5f5;
            color: crimson;
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
          }
        }
      }

      .subTitle {
        color: #374935;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        text-align: start;
        opacity: 0.5;
      }
    }

    .modalBody {
      font-family: "BR Firma";
      padding-block-start: 10px;
    }
  }
}
/*=====       END OF MODAL SECTION         ======*/
