@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

// SLIDE ANIMATIONS
@-webkit-keyframes slideRight {
  from {
    transform: translateX(0px) rotateY(0deg);
  }
  to {
    transform: translateX(-7px) rotateY(180deg);
    color: colors.$mint;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(0px) rotateY(0deg);
  }
  to {
    transform: translateX(-7px) rotateY(180deg);
    color: colors.$mint;
  }
}

.sectionHeader {
  width: 100%;
  @include mixins.flex_align;
  @include mixins.flex_justifyBetween;

  .brandColor {
    color: colors.$brandColor;
  }

  .sectionHeaderTitle {
    font-weight: 600;
    font-size: fn.rem(24);
    line-height: 1.5;

    // SCREENS 768px and LARGER
    @include screens.breakpoint-up(md) {
      font-size: fn.rem(40);
      line-height: fn.rem(50);
    }
  }

  .sectionHeaderLink {
    font-weight: 400;
    font-size: fn.rem(12);
    line-height: fn.rem(1.2);
    transition: var.$transition;

    // SCREENS 768px and LARGER
    @include screens.breakpoint-up(md) {
      font-size: fn.rem(16);
      line-height: fn.rem(20);
      @include mixins.flex_align;
      @include mixins.flex_justifyBetween;
      gap: fn.rem(9);
    }

    &:hover {
      .sectionHeaderLinkText {
        color: colors.$mint;
        transition: var.$transition;
      }

      .sectionHeaderLinkIcon {
        -webkit-animation: slideRight 500ms ease-in forwards alternate;
        animation: slideRight 500ms ease-in forwards alternate;
      }
    }

    .sectionHeaderLinkText {
      transform: translateY(-2px);
      color: colors.$brandColor;
      transition: var.$transition;

      // SCREENS 768px and LARGER
      @include screens.breakpoint-down(md) {
        display: none;
      }
    }

    .sectionHeaderLinkIcon {
      font-size: fn.rem(18);
      transition: var.$transition;

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(md) {
        font-size: fn.rem(20);
      }
    }
  }
}
