@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.homeHeroBanner {
  width: 100%;
  display: flex;
  justify-content: center;

  // SCREENS 768px and LARGER
  @include screens.breakpoint-up(md) {
    background-image: url("../../../../assests/images/HomePage/BannerOverlay.webp");
    background-color: colors.$mint-alt;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .homeHeroBannerContainer {
    width: 100%;
    display: grid;
    justify-content: center;

    .homeHeroImage,
    .heading {
      min-height: 26dvh;
      grid-area: heroBanner;

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(md) {
        height: unset;
      }
    }

    .homeHeroImage {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }

    .heading {
      height: 100%;
      max-width: 100vw;
      display: flex;
      background-color: rgba(0, 0, 0, 0.3);
      flex-direction: column;
      margin-block: auto;
      @include mixins.flex_column;
      @include mixins.flex_justify;

      .captionOverflow {
        padding-block: 1.5px;
        overflow: hidden;

        .homeHeroCaption {
          width: max-content;
          max-width: 100vw;
          white-space: nowrap;
          margin: 0;
          line-height: 117%;
        }

        .slideUp {
          color: colors.$light;
          font-weight: 700;
          font-size: fn.rem(28);
          // line-height: 110%;
          animation: slideUp 0.9s cubic-bezier(0.65, 0, 0.35, 1) both;

          // SCREENS 768px and LARGER
          @include screens.breakpoint-up(md) {
            font-size: fn.rem(40);
          }

          // SCREENS 768px and LARGER
          @include screens.breakpoint-up(lg) {
            font-size: fn.rem(60);
          }
        }
      }

      @for $i from 1 through 3 {
        .captionOverflow {
          animation-delay: 300ms;

          &:nth-child(#{$i}) {
            .homeHeroCaption {
              animation-delay: calc($i * var.$animation-timing);
            }
          }
        }
      }
    }

    @keyframes slideUp {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}
