@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

/*==============================================
  =            MODAL SECTION                   =
  =============================================*/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  min-height: 100%;
  overflow-y: scroll;

  .modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    min-height: inherit;
    @include mixins.flex_centered;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(4px);

    .modalContent {
      max-width: 90%;
      // margin-block: fn.rem(32);
      background-color: colors.$light;
      padding-block-start: fn.rem(16);
      padding-block-end: fn.rem(20);
      padding-inline: fn.rem(16);
      border-radius: var.$borderRadius;

      @include screens.breakpoint-up("lg") {
        width: 50%;
        max-width: 700px;
      }

      .modalHeading {
        width: 100%;
        padding-block-end: fn.rem(16);
        @include mixins.flex_align;
        @include mixins.flex_justifyEnd;

        .modalCloseBtn {
          cursor: pointer;
          background: colors.$light;
          color: colors.$brandColor;
          font-size: fn.rem(24);
          padding: fn.rem(4);
          @include mixins.flex_centered;
          border-radius: 9999vw;
          outline: transparent;
          border: transparent;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.12);
          transition: var.$transition;

          &:hover {
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
          }
        }
      }

      .modalBody {
        width: 100%;

        .modalLoader {
          width: 100%;
          padding-block: 10%;
          @include mixins.flex_centered;
        }

        .modalBodyContent {
          width: 100%;
          @include mixins.flex_column;
          @include mixins.flex_centered;

          @include screens.breakpoint-up("md") {
            padding-inline: fn.rem(32);
          }

          .addToCartBtnContainer {
            width: 100%;
            @include mixins.flex_centered;
            padding-block: fn.rem(32);

            .addToCartBtn {
              cursor: pointer;
              background: colors.$brandColor;
              color: colors.$light;
              font-weight: 700;
              font-size: fn.rem(16);
              line-height: fn.rem(20);
              border-radius: var.$borderRadiusRounded;
              padding-block: fn.rem(14);
              padding-inline: fn.rem(40);
              transition: var.$transition;

              &:disabled {
                cursor: not-allowed;
                background: colors.$brandColor-50;
              }

              &:disabled:hover {
                background: colors.$brandColor-50;
              }

              &:hover {
                background: colors.$mint;
              }
            }
          }
        }
      }
    }
  }
}

/*=====       End of MODAL SECTION         ======*/
