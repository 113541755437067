@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
// @use '../../../scss/utils/breakpoints' as screens;

.extrasCard {
  @include mixins.flex_centered;
  @include mixins.flex_column;
  padding-block-end: fn.rem(16);

  .extrasImage {
    width: 100px;
    aspect-ratio: 1/1;
    border-radius: var.$borderRadiusSm;
  }

  .extrasCardBody {
    padding-block: fn.rem(4);
    @include mixins.flex_column;
    @include mixins.flex_centered;
    gap: fn.rem(4);

    .extrasName {
      color: colors.$text-alt;
      font-weight: 400;
      font-size: fn.rem(14);
      line-height: 140%;
      text-align: center;
    }

    .extrasPrice {
      font-weight: 600;
      font-size: fn.rem(14);
      line-height: 140%;
      color: colors.$mint;
    }

    .extrasControls {
      padding-block: fn.rem(4);

      .extrasControlGroup {
        width: max-content;
        @include mixins.flex_align;
        border-radius: fn.rem(25);
        border: 2px solid colors.$brandColor-light;

        .extrasQuantityMinus {
          border-radius: fn.rem(16) 0 0 fn.rem(16);
        }
        .extrasQuantityPlus {
          border-radius: 0 fn.rem(16) fn.rem(16) 0;
        }

        .extrasQuantityPlus,
        .extrasQuantityMinus {
          background: colors.$brandColor-alt;
          transition: var.$nav-transition;

          & > svg {
            font-size: fn.rem(14);
            color: colors.$text-alt;
            transition: var.$nav-transition;
          }

          &:disabled {
            cursor: not-allowed;
            background: colors.$brandBorder;

            & > svg {
              color: colors.$light;
            }
          }
        }

        .extrasQuantityMinus,
        .extrasQuantityPlus,
        .extrasQuantity {
          width: fn.rem(30);
          height: fn.rem(25);
          color: colors.$base-text;
          @include mixins.flex_centered;
        }

        .extrasQuantity {
          background: colors.$light;
          font-size: fn.rem(14);
        }
      }
    }
  }
}
