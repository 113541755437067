@use "../../../scss/global/fonts" as fonts;
@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

.singlePlanTypeModal {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(fn.rem(4));
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include mixins.flex_centered;
  z-index: 1;

  .singlePlanTypeModalContainer {
    width: 90%;
    // max-height: 90%;
    background-color: white;
    border-radius: var.$borderRadius;
    position: relative;

    @media (width >=768px) {
      width: 50%;
    }

    .modalLoader {
      width: 100%;
      padding-block: 10%;
      @include mixins.flex_centered;
    }

    .singlePlanTypeModalCloseBtn {
      cursor: pointer;
      background: colors.$light;
      color: colors.$base-text;
      padding: fn.rem(4);
      font-size: fn.rem(20);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      border-radius: var.$borderRadiusCircle;
      position: absolute;
      top: fn.rem(20);
      right: fn.rem(20);
      transition: var.$nav-transition;

      &:hover {
        background: colors.$brandColor;
        color: colors.$light;
        box-shadow:
          rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
    }

    .singlePlanTypeModalContent {
      width: 100%;
      padding-block-start: 15%;
      padding-block-end: 5%;
      padding-inline: 7%;
      @include mixins.flex_column;
      @include mixins.flex_centered;

      @media (width >768px) {
        padding-inline: 10%;
      }

      .singlePlanTypeModalHeading {
        width: 90%;
        color: colors.$base-text;
        text-align: center;
        @include mixins.flex_column;
        gap: fn.rem(14);

        .singlePlanTypeModalTitle {
          font-weight: 600;
          // font-size: fn.rem(30);
          font-size: clamp(1.6rem, 2.5vw, fn.rem(30));
          line-height: 110%;
        }

        .singlePlanTypeModalSubTitle {
          font-weight: 400;
          font-size: fn.rem(16);
          line-height: 160%;
        }
      }

      .singlePlanTypeModalForm {
        width: 100%;
        @include mixins.flex_column;
        @include mixins.flex_alignStart;
        @include mixins.flex_justify;

        .formGroup {
          width: inherit;
          padding-block-start: fn.em(20);
          @include mixins.flex_column;
          @include mixins.flex_alignStart;
          @include mixins.flex_justify;
          gap: fn.rem(4);

          @media (width >768px) {
            padding-block-start: fn.em(36);
            gap: fn.rem(12);
          }

          .formLabel {
            width: 100%;
            color: colors.$base-text;
            font-weight: 400;
            font-size: fn.rem(14);
            line-height: 140%;
          }

          .formField {
            width: 100%;
            background: colors.$light;
            color: colors.$base-text;
            font-weight: 400;
            font-size: fn.rem(14);
            line-height: fn.rem(18);
            padding-block: fn.rem(10);
            padding-inline: fn.rem(16);
            outline: transparent;
            border: 1px solid colors.$inputBorder;
            border-radius: var.$borderRadiusSm;

            &::placeholder {
              font-family: fonts.$base-font;
              color: colors.$base-text-05;
            }
          }

          .formFieldSelect {
            .formFieldOption {
              background: colors.$light;
              color: colors.$base-text;
              font-weight: 400;
              font-size: fn.rem(16);
              line-height: 1.2;
            }
          }
        }

        .formDynamicData {
          width: 100%;
          @include mixins.flex_column;
          @include mixins.flex_alignStart;
          @include mixins.flex_justify;

          @media (width >768px) {
            width: 100%;
            @include mixins.flex_row;
            gap: 10%;
          }
        }
      }
    }

    .singlePlanTypeModalSubmitBtnContainer {
      width: 100%;
      padding-block-start: fn.rem(32);
      padding-block-end: fn.rem(24);
      @include mixins.flex_centered;

      @media (width >=768px) {
        padding-block-start: fn.rem(48);
      }

      .singlePlanTypeModalSubmitBtn {
        cursor: pointer;
        width: 80%;
        background: colors.$brandColor;
        color: colors.$light;
        font-size: fn.rem(16);
        font-weight: 500;
        font-size: fn.rem(14);
        line-height: fn.rem(18);
        padding-block: fn.rem(14);
        padding-inline: fn.rem(24);
        border: 1px solid colors.$brandColor;
        border-radius: var.$borderRadiusRounded;
        transition: var.$transition;

        &:hover {
          background: colors.$mint;
          border: 1px solid colors.$mint;
        }
      }
    }
  }
}
