@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

.signUp {
  width: 100%;
  min-height: 100dvh;
  margin: auto;

  .signUpSection {
    width: 100%;
    height: inherit;
    padding-block: fn.rem(30);
    padding-inline: fn.rem(36);
    @include mixins.flex_align;
    @include mixins.flex_justifyBetween;

    .authFormContainer,
    .imgContainer {
      width: 50%;
      height: inherit;
    }

    .authFormContainer {
      max-width: 455px;
      width: 100%;
      margin-inline: auto;
      margin-block: auto;
      @include mixins.flex_column;
      // @include mixins.flex_justify;
      gap: fn.rem(16);

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(xxl) {
        gap: fn.rem(24);
      }

      .brandLogoContainer {
        align-self: flex-start;

        .brandLogo {
          width: 72px;
          aspect-ratio: 1/1;
        }
      }

      .formHeading {
        .formTitle {
          color: colors.$base-text;
          font-weight: 700;
          font-size: fn.rem(50);
          line-height: fn.rem(63);
        }

        .formSubTitle {
          color: colors.$muted-4;
          font-weight: 400;
          font-size: fn.rem(16);
          line-height: 140%;
        }
      }

      .authForm {
        width: 100%;

        .formGroup {
          width: 100%;
          padding-block: fn.rem(10);
          @include mixins.flex_column;
          @include mixins.flex_alignStart;
          gap: fn.rem(4);

          // &:nth-of-type(2n + 1) {
          //   color: colors.$danger;
          // }

          .formFieldLabel {
            color: colors.$base-text;
            font-weight: 400;
            font-size: fn.rem(14);
            line-height: 140%;
          }

          .formInputField {
            width: 100%;
            background: transparent;
            color: colors.$base-text;
            caret-color: colors.$brandColor underscore;
            font-weight: 400;
            font-size: fn.rem(14);
            line-height: fn.rem(18);
            padding-block: fn.rem(10);
            padding-inline: fn.rem(16);
            outline: transparent;
            border: 1px solid colors.$mute-007;
            border-radius: var.$borderRadiusSm;
            transition: var.$transition;

            // SCREENS 768px and LARGER
            @include screens.breakpoint-up(md) {
              padding-block: fn.rem(12);
            }

            // SCREENS 768px and LARGER
            @include screens.breakpoint-up(xxl) {
              padding-block: fn.rem(16);
            }

            &::placeholder,
            &:placeholder-shown,
            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder {
              color: colors.$mute-1;
            }
          }
        }

        .formSubmit {
          padding-block-start: var.$paddingLg;

          .submitBtn {
            width: 100%;
            color: colors.$light;
            background: colors.$brandColor;
            font-weight: 700;
            font-size: fn.rem(18);
            line-height: fn.rem(23);
            padding-block: fn.rem(13);
            border-radius: var.$borderRadiusRounded;
            transition: var.$transition;

            &:hover,
            &:active {
              background: colors.$mint;
            }
          }

          .formRedirectText {
            color: colors.$mute-04;
            font-weight: 400;
            font-size: fn.rem(14);
            line-height: fn.rem(18);
            padding-block: fn.rem(20);
            @include mixins.flex_centered;

            .formRedirect {
              font-weight: bold;
              color: colors.$mute-04;
              padding-inline-start: fn.rem(6);
              transition: var.$transition;

              &:hover {
                color: colors.$mint;
              }
            }
          }
        }
      }
    }

    .imgContainer {
      display: grid;

      & > * {
        grid-area: stack;
      }

      // SCREENS 768px and SMALLER
      @include screens.breakpoint-down(md) {
        display: none;
      }

      .miniTestimonials {
        // max-width: 40ch;
        background: colors.$glass;
        color: colors.$light;
        backdrop-filter: blur(fn.rem(41));
        border: 1px solid colors.$light;
        border-radius: var.$borderRadiusLg;
        padding-block: fn.rem(20);
        padding-inline: fn.rem(33);
        margin-block-start: auto;
        margin-block-end: fn.rem(50);
        margin-inline: auto;
        @include mixins.flex_column;
        @include mixins.flex_align;
        gap: fn.rem(32);

        // // SCREENS 1400px and LARGER
        // @include screens.breakpoint-up('2xl') {
        //   margin-block-end: fn.rem(50);
        // }

        .blockQuote {
          max-width: 24.5ch;
          color: colors.$light;
          font-weight: 600;
          font-size: fn.rem(22);
          line-height: 140%;
        }

        .ratingControls {
          width: 100%;
          @include mixins.flex_align;
          @include mixins.flex_justifyBetween;

          .testimonialCredits {
            @include mixins.flex_column;
            @include mixins.flex_alignStart;
            gap: fn.rem(8);

            .testimonialName {
              color: colors.$light;
              font-weight: 600;
              font-size: fn.rem(18);
              line-height: 140%;
            }

            .ratings {
              @include mixins.flex_alignStart;
              gap: fn.rem(2);
            }
          }

          .controls {
            @include mixins.flex_centered;
            gap: fn.rem(8);

            .controlIcon {
              background: transparent;
              color: colors.$light;
              font-size: fn.rem(32);
              padding: fn.rem(6);
              border: 3px solid colors.$light;
              border-radius: 50%;
              transform: scale(1);
              transition: var.$transition;

              &:hover,
              &:active {
                background: colors.$brandColor;
                color: colors.$light;
                border: 3px solid colors.$brandColor;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              }

              &:active {
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }
  }
}
