@use "../utils/functions" as fn;
@use "../global/colors" as colors;

// PADDINGS
$containerPadding: 2%;
$padding: fn.rem(4);
$paddingXs: fn.rem(8);
$paddingSm: fn.rem(16);
$paddingSmMid: fn.rem(24);
$paddingMd: fn.rem(32);
$paddingLg: fn.rem(48);
$paddingLgMid: fn.rem(56);
$paddingXl: fn.rem(64);
$paddingXxl: fn.rem(80);

// MARGINS
$marginSm: fn.rem(16);
$marginMd: fn.rem(32);
$marginLg: fn.rem(48);
$marginXl: fn.rem(64);
$marginXxl: fn.rem(80);

// BORDERS
$border: 1px solid colors.$burn-alt;
$borderRadiusXs: fn.rem(3);
$borderRadiusSm: fn.rem(5);
$borderRadius: fn.rem(8);
$borderRadiusLg: fn.rem(10);
$borderRadiusRounded: fn.rem(25);
$borderRadiusCircle: 50%;
$navBorderRadius: fn.rem(8) fn.rem(8) 0 0;
$footerBorderRadius: 0 0 fn.rem(8) fn.rem(8);

// WIDTH
$navBrandWidth: 25%;

// ANIMATION
$transition: all 500ms ease-in;
$nav-transition: all 300ms ease-in;
$animation-timing: 300ms;
// $carousel-items: 6;
// $animation-delay-fraction: calc($animation-timing / $carousel-items);
// $animation-steps-fraction: calc(100 / $carousel-items);
// $slide-change-timing: 3; // percentage of total animation cycle
