@use "../../scss/global/colors" as colors;
@use "../../scss/utils/mixins" as mixins;
@use "../../scss/utils/functions" as fn;
@use "../../scss/utils/variables" as var;
@use "../../scss/utils/breakpoints" as screens;

.mealPlans {
  width: 100%;
  height: 100%;
  padding-block: 10%;

  // SCREENS 768px and LARGER
  @include screens.breakpoint-up(md) {
    padding-block: 5%;
  }

  .modalLoader {
    width: 100%;
    padding-block: 10%;
    @include mixins.flex_centered;
  }

  .singleMealPlan {
    width: 100%;
    height: 100%;

    .singleMealPlanCard {
      width: 100%;
      height: 100%;
      @include mixins.flex_align;
      @include mixins.flex_column;
      gap: fn.rem(22);

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up(md) {
        @include mixins.flex_row;
        height: 450px;
      }

      .singleMealPlanCardImage {
        height: 100%;
        width: calc(100% - 22px);
        @include mixins.flex_centered;
      }

      .singleMealPlanCardContent {
        width: calc(100% - 22px);
        height: 100%;
        background: colors.$alt-bg;
        border-radius: var.$borderRadiusLg;
        padding-inline: 3%;
        @include mixins.flex_column;
        @include mixins.flex_alignStart;
        @include mixins.flex_justify;
        gap: fn.rem(16);

        .singleMealPlanCardTitle {
          color: colors.$base-text;
          font-weight: 600;
          font-size: clamp(fn.rem(22), 2.5vw + fn.rem(16), fn.rem(30));
          line-height: 110%;
        }

        .singleMealPlanCardDuration,
        .singleMealPlanCardPrice {
          @include mixins.flex_alignStart;
          gap: fn.rem(16);
        }

        .singleMealPlanCardDurationTitle,
        .singleMealPlanCardPriceTitle {
          color: colors.$base-text;
          border-radius: var.$borderRadiusSm;
          font-weight: 500;
          font-size: fn.rem(14);
          padding-inline: fn.rem(6);
        }

        .singleMealPlanCardDuration {
          width: 100%;
          line-height: 160%;

          .singleMealPlanCardDurationTitle {
            background: colors.$brandColor-light;
          }

          .singleMealPlanCardDurationContent {
            color: colors.$base-text;
            font-weight: 600;
            font-size: fn.rem(16);
            line-height: 160%;
          }
        }

        .singleMealPlanCardPrice {
          width: 100%;
          line-height: 160%;

          .singleMealPlanCardPriceTitle {
            background: colors.$mint-bg;
          }

          .singleMealPlanCardPriceContent {
            color: colors.$mint;
            font-weight: 600;
            font-size: fn.rem(16);
          }
        }

        .singleMealPlanCardBtnContainer {
          width: 100%;
          padding-block: 5%;

          .singleMealPlanCardBtn {
            width: 100%;
            background: colors.$brandColor;
            color: colors.$light;
            border-radius: var.$borderRadiusRounded;
            padding-block: fn.rem(10);
          }
        }
      }
    }
  }
}
