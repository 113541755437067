@use "../../scss/global/colors" as colors;
@use "../../scss/utils/mixins" as mixins;
@use "../../scss/utils/functions" as fn;
@use "../../scss/utils/variables" as var;
@use "../../scss/utils/breakpoints" as screens;

.singleOrderDetails {
  width: 100%;
  min-height: 60dvh;
  padding-block: 5%;

  .singleOrderDetailsContainer {
    width: 100%;
    @include mixins.flex_alignStart;
    @include mixins.flex_justifyStart;
    gap: 5%;

    .orderDetailsCards {
      width: 60%;
      @include mixins.flex_centered;
      @include mixins.flex_column;
      gap: fn.rem(38);

      // SCREENS 768px and LARGER
      @include screens.breakpoint-down(sm) {
        width: 100%;
      }

      .reciepientsCard {
        width: 100%;
        border-radius: var.$borderRadiusLg;

        .reciepientsCardHeader {
          background: colors.$brandColor-bg;
          color: colors.$base-text;
          font-size: fn.rem(16);
          font-weight: 500;
          line-height: 140%;
          padding-block: fn.rem(8);
          padding-inline: fn.rem(20);
        }

        .reciepientsCardItem {
          width: 100%;
          background: colors.$alt-bg;
          @include mixins.flex_align;
          @include mixins.flex_justifyBetween;
          padding-block: fn.rem(14);
          padding-inline: fn.rem(20);

          .reciepientsCardItemTitle,
          .reciepientsCardItemText {
            color: #041d05;
            font-size: fn.rem(14);
            font-weight: 500;
            line-height: 160%;
          }

          .reciepientsCardItemText {
            font-weight: 400;
          }
        }
      }

      .cardItemDivider {
        width: 40%;
        // border: 1px dashed colors.$brandColor-50;
        border: 1px dashed colors.$brandColor-divider;
      }

      .priceCard {
        width: 100%;
        border-radius: var.$borderRadiusLg;

        .priceCardItem {
          width: 100%;
          background: colors.$alt-bg;
          @include mixins.flex_align;
          @include mixins.flex_justifyBetween;
          padding-block: fn.rem(14);
          padding-inline: fn.rem(20);

          &:last-of-type {
            background: colors.$brandColor-bg;
          }

          .mealCostTitle,
          .mealDeliveryFeeTitle,
          .mealcostPrice,
          .mealdeliveryFeePrice {
            font-weight: 500;
            font-size: fn.rem(16);
            line-height: 140%;

            // SCREENS 768px and LARGER
            @include screens.breakpoint-down(sm) {
              font-size: fn.rem(14);
            }
          }

          .mealcostPrice,
          .mealdeliveryFeePrice {
            font-weight: 600;
          }

          .mealTotalPriceTitle,
          .mealTotalPrice {
            font-weight: 500;
            font-size: fn.rem(18);
            line-height: 140%;

            // SCREENS 768px and LARGER
            @include screens.breakpoint-down(sm) {
              font-size: fn.rem(16);
            }
          }

          .mealTotalPrice {
            font-weight: 700;
          }
        }
      }

      .reOrderBtn {
        background: colors.$brandColor;
        color: colors.$base-bg;
        font-weight: 600;
        font-size: fn.rem(14);
        line-height: 140%;
        padding-block: fn.rem(14);
        padding-inline: fn.rem(24);
        align-self: flex-start;
        border-radius: var.$borderRadiusRounded;
        transition: var.$transition;

        &:hover {
          background: colors.$mint;
        }
      }
    }

    .orderDetailsIllustrationContainer {
      width: 40%;
      background-color: colors.$mint-bg2;

      // SCREENS 768px and LARGER
      @include screens.breakpoint-down(sm) {
        display: none;
      }

      .orderDetailsIllustration {
        width: 100%;
      }

      .orderDetailsIllustrationTextContainer {
        width: 100%;
        padding-block: fn.rem(32);
        padding-inline: fn.rem(32);

        .orderDetailsIllustrationText {
          font-weight: 500;
          font-size: fn.rem(14);
          line-height: 160%;
          text-align: center;
        }
      }
    }
  }
}
