@use "../../../../scss/global/colors" as colors;
@use "../../../../scss/utils/mixins" as mixins;
@use "../../../../scss/utils/functions" as fn;
@use "../../../../scss/utils/variables" as var;
@use "../../../../scss/utils/breakpoints" as screens;

.mealPlansHowSection {
  width: 100%;
  padding-block-start: fn.rem(60);
  padding-block-end: fn.rem(20);

  @media (width >=768px) {
    padding-block-start: fn.rem(120);
  }

  .mealPlansHowContainer {
    width: 100%;
    @include mixins.flex_centered;
    @include mixins.flex_column;
    gap: fn.rem(20);

    @media (width >=768px) {
      gap: fn.rem(40);
    }

    .mealPlansHowTitle {
      color: colors.$base-text;
      font-weight: 600;
      font-size: clamp(fn.rem(28), 3.5vw + fn.rem(16), fn.rem(40));
      line-height: fn.rem(50);
    }

    .mealPlansHowItWorks {
      width: 100%;
      @include mixins.flex_centered;
      flex-wrap: wrap;
      gap: fn.rem(32);

      .mealPlansHowItWorksCards {
        padding-inline: fn.rem(14);
        @include mixins.flex_centered;
        @include mixins.flex_column;
        gap: fn.rem(16);

        .cardImage {
          width: fn.rem(100);
          aspect-ratio: 1/1;

          @media (width >=768px) {
            width: fn.rem(150);
          }
        }

        .cardText {
          max-width: 12ch;
          color: colors.$base-text;
          font-weight: 600;
          font-size: fn.rem(14);
          line-height: fn.rem(20);
          text-align: center;

          @media (width >=768px) {
            font-size: fn.rem(16);
          }
        }
      }
    }
  }
}
