@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/variables" as var;
@use "../../../scss/utils/breakpoints" as screens;

.orderSuccessModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(fn.rem(4));
  @include mixins.flex_centered;
  z-index: 1;

  .orderSuccessModalContainer {
    width: fn.rem(410);
    max-width: 90%;
    max-height: 90%;
    background-color: white;
    border-radius: var.$borderRadius;
    padding-block: fn.rem(46);
    padding-inline: fn.rem(40);
    @include mixins.flex_column;
    @include mixins.flex_centered;
    gap: fn.rem(28);
    overflow: auto;

    .orderSuccessModalContent {
      width: 100%;
      height: 100%;
      @include mixins.flex_column;
      @include mixins.flex_centered;
      gap: fn.rem(20);

      .orderSuccessModalCaption {
        width: 100%;
        @include mixins.flex_column;
        @include mixins.flex_centered;
        gap: fn.rem(20);

        .orderSuccessModalTitle {
          color: colors.$base-text;
          font-weight: 600;
          font-size: fn.rem(22);
          line-height: 110%;
        }

        .orderSuccessModalDetails {
          color: colors.$base-text;
          font-weight: 400;
          font-size: fn.rem(16);
          line-height: 140%;
          text-align: center;
        }
      }
    }

    .orderSuccessModalCloseBtn {
      cursor: pointer;
      background-color: colors.$light;
      color: colors.$brandColor;
      font-size: fn.rem(16);
      font-weight: 500;
      font-size: fn.rem(14);
      line-height: fn.rem(18);
      padding-block: fn.rem(14);
      padding-inline: fn.rem(24);
      border: 1px solid colors.$brandColor;
      border-radius: var.$borderRadiusRounded;
      transition: var.$transition;

      &:hover {
        background-color: colors.$mint;
        color: colors.$light;
      }
    }
  }
}
