@use "../../scss/global/colors" as colors;
@use "../../scss/utils/mixins" as mixins;
@use "../../scss/utils/functions" as fn;
@use "../../scss/utils/variables" as var;
@use "../../scss/utils/breakpoints" as screens;

.orderHistory {
  width: 100%;
  min-height: 60dvh;

  .modalLoader {
    width: 100%;
    padding-block: 10%;
    @include mixins.flex_centered;
  }

  .orderHistoryContainer {
    width: 100%;
    padding-block: fn.rem(60);
    @include mixins.flex_alignStart;
    @include mixins.flex_column;
    gap: fn.rem(32);

    .orderTitle {
      color: colors.$base-text;
      font-weight: 600;
      font-size: fn.rem(40);
      line-height: fn.rem(50);
    }

    .orderStatusDetailsContainer {
      width: 100%;
      @include mixins.flex_column;
      @include mixins.flex_alignStart;
      @include mixins.flex_justifyBetween;
      gap: fn.rem(32);

      .orderStatusBtnContainer {
        @include mixins.flex_centered;
        background: colors.$mint-mild;
        border-radius: var.$borderRadiusRounded;

        .orderStatusBtn {
          cursor: pointer;
          font-weight: 600;
          font-size: fn.rem(16);
          line-height: fn.rem(20);
          padding-block: fn.rem(14);
          padding-inline: fn.rem(32);
          border-radius: var.$borderRadiusRounded;
          @include mixins.flex_centered;
          transition: var.$transition;

          @include screens.breakpoint-down(md) {
            padding-block: fn.rem(10);
            padding-inline: fn.rem(16);
          }

          &:hover,
          &:active {
            background: colors.$brandColor;
            color: colors.$light;
          }
        }

        .activeStatus {
          background: colors.$mint;
          color: colors.$light;
          transition: var.$transition;
        }

        .inActiveStatus {
          background: transparent;
          color: colors.$mint;
          transition: var.$transition;
        }
      }
    }
  }
}
