@use "../../scss/global/colors" as colors;
@use "../../scss/utils/mixins" as mixins;
@use "../../scss/utils/functions" as fn;
@use "../../scss/utils/variables" as var;
@use "../../scss/utils/breakpoints" as screens;

.menu {
  width: 100%;
  padding-block: fn.rem(60);

  .loader {
    width: 100%;
    min-height: 30dvh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .categoriesContainer {
    width: 100%;
    padding-block-end: fn.rem(32);

    .productCategories {
      width: 100%;
      // @include mixins.flex_align;
      // @include mixins.flex_justifyBetween;
      padding-inline: fn.rem(16);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: fn.rem(8);
      row-gap: fn.rem(16);

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up("md") {
        padding-inline: unset;
        grid-template-columns: repeat(4, 1fr);
      }

      // SCREENS 1400px and LARGER
      @include screens.breakpoint-up("lg") {
        grid-template-columns: repeat(6, 1fr);
        column-gap: fn.rem(32);
        row-gap: fn.rem(16);
      }

      .category {
        // background: colors.$mint-01;
        // color: colors.$mint;
        font-weight: 400;
        font-size: fn.rem(12);
        line-height: fn.rem(18);
        padding-block: fn.rem(6);
        padding-inline: fn.rem(24);
        border-radius: var.$borderRadiusRounded;
        transition: var.$transition;

        // SCREENS 768px and LARGER
        @include screens.breakpoint-up("md") {
          font-size: fn.rem(14);
        }
      }

      .categoryInActive {
        background: colors.$mint-01;
        color: colors.$mint;
        transition: var.$transition;
      }

      .categoryActive {
        background: colors.$mint;
        color: colors.$light;
        transition: var.$transition;
      }
    }
  }

  .menuContainer {
    width: 100%;
    min-height: 40dvh;
    @include mixins.flex_centered;

    .menuCards {
      width: 100%;
      display: grid;
      place-items: center;
      grid-template-columns: 1fr;
      gap: fn.rem(32);
      // @include mixins.flex_align;
      // @include mixins.flex_justifyBetween;
      // flex-wrap: wrap;

      // SCREENS 768px and LARGER
      @include screens.breakpoint-up("md") {
        grid-template-columns: repeat(3, 1fr);
      }

      // SCREENS 1400px and LARGER
      @include screens.breakpoint-up("lg") {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
