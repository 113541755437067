@use "../../../scss/global/colors" as colors;
@use "../../../scss/utils/functions" as fn;
@use "../../../scss/utils/mixins" as mixins;
@use "../../../scss/utils/breakpoints" as screens;
@use "../../../scss/utils/variables" as var;

.navbarContainer {
  width: 100%;
  padding-block: fn.rem(18);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  transition: var.$nav-transition;

  &.invertNavBar {
    background: colors.$nav-blur;
    box-shadow: 0px 10px 10px -10px colors.$brandColor-light;
    backdrop-filter: blur(54px);
    -webkit-backdrop-filter: blur(54px);
    transition: var.$nav-transition;
  }

  &.navSolidBg {
    background: colors.$mint-light;
    backdrop-filter: blur(54px);
    -webkit-backdrop-filter: blur(54px);
    transition: var.$nav-transition;
  }

  .navbar {
    width: 100%;
    @include mixins.flex_align;
    @include mixins.flex_column;
    @include mixins.flex_justifyAround;
    transition: var.$nav-transition;

    // SCREENS 1025px and LARGER
    @include screens.breakpoint-up(lg) {
      @include mixins.flex_row;
      @include mixins.flex_justifyBetween;
    }

    .navBrand {
      width: 100%;
      @include mixins.flex_align;
      @include mixins.flex_justifyBetween;

      // // SCREENS 768px and SMALLER
      // @include screens.breakpoint-down(md) {
      //   padding-inline: fn.rem(56);
      // }

      // // SCREENS 768px and SMALLER
      // @include screens.breakpoint-down(sm) {
      //   padding-inline: fn.rem(32);
      // }

      // SCREENS 768px and SMALLER
      @include screens.breakpoint-up(md) {
        padding-block: fn.rem(17);
      }

      // SCREENS 1025px and LARGER
      @include screens.breakpoint-up(lg) {
        padding-block: 0;
        width: unset;
      }

      .navLogoContainer {
        width: fn.rem(50);

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          width: unset;
        }

        .navLogo {
          width: inherit;
          aspect-ratio: 1/1;
        }
      }

      .hamburger {
        font-size: fn.rem(32);

        &:hover,
        &:active {
          color: colors.$burn;
        }

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          display: none;
        }
      }
    }

    .navItems {
      width: 100%;
      height: 95dvh;
      @include mixins.flex_column;
      @include mixins.flex_centered;
      padding-block-end: fn.rem(48);

      // SCREENS 768px and SMALLER
      @include screens.breakpoint-down(lg) {
        padding-block-end: fn.rem(48);
        border-block-start: 2px solid colors.$brandColor-mid;

        &.navOpen {
          width: 100%;
          background-color: colors.$mint-light;
          box-shadow: 0 0 5px colors.$burn-alt;
          backdrop-filter: blur(fn.rem(14));
          -webkit-backdrop-filter: blur(fn.rem(14));
          position: absolute;
          right: 0;
          top: 7.5vh;
          transition: var.$nav-transition;
        }

        &.navClosed {
          width: 0%;
          position: absolute;
          right: -50%;
          top: 7.5vh;
          overflow-x: hidden;
          transition: var.$nav-transition;
        }
      }

      // SCREENS 1025px and LARGER
      @include screens.breakpoint-up(lg) {
        width: 90%;
        height: 100%;
        padding-block-end: 0;
        @include mixins.flex_row;
        @include mixins.flex_align;
        @include mixins.flex_justifyBetween;
      }

      .navItemsLeft,
      .navItemsRight {
        @include mixins.flex_column;
        @include mixins.flex_align;
        @include mixins.flex_justifyAround;

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          @include mixins.flex_row;
        }

        .navLink {
          font-weight: 400;
          font-size: fn.rem(15);
          line-height: 1.5;
          transition: var.$transition;

          .inActiveNavLink {
            color: colors.$base-text;
            transition: var.$transition;

            &:hover {
              color: colors.$mint;
            }
          }

          .activeNavLink {
            color: colors.$brandColor;
            transition: var.$transition;

            &:hover {
              color: colors.$mint;
            }
          }
        }

        .navLinkIcon {
          color: colors.$mint;
          font-size: fn.rem(24);
          transition: var.$nav-transition;
          position: relative;

          &:hover {
            color: colors.$brandColor;
          }

          & ~ .navCartIcon {
            &:hover {
              .navCartBadge {
                background: colors.$mint;
              }
            }

            .navCartBadge {
              width: fn.rem(14);
              height: fn.rem(14);
              background: colors.$brandColor;
              color: colors.$light;
              font-size: fn.rem(10);
              padding: fn.rem(8);
              border-radius: 50%;
              position: absolute;
              top: -25%;
              right: -20%;
              @include mixins.flex_centered;
              transition: var.$nav-transition;
            }
          }
        }

        .navLinkBtn {
          // SCREENS 1025px and LARGER
          @include screens.breakpoint-up(lg) {
            &:nth-last-of-type(2) {
              padding-inline-start: fn.rem(50);
            }
          }
        }
      }

      .navItemsLeft {
        height: 40%;

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          gap: fn.rem(52);
        }
      }

      .navItemsRight {
        height: 60%;
        padding-block-end: fn.rem(150);

        // SCREENS 1025px and LARGER
        @include screens.breakpoint-up(lg) {
          padding-block-end: unset;
          gap: fn.rem(24);
        }

        .userAvatarContainer {
          @include mixins.flex_align;
          gap: fn.rem(20);

          .userAvatar {
            width: 33px;
            aspect-ratio: 1/1;
            border-radius: 99999vw;
            @include mixins.flex_centered;
          }

          .userDetails {
            @include mixins.flex_centered;
            gap: fn.rem(10);

            .userName {
              color: colors.$base-text;
              font-weight: 400;
              font-size: fn.rem(16);
              line-height: fn.rem(20);

              .userNameGreet {
                color: colors.$base-text-02;
              }
            }

            .dropDownMenu {
              cursor: pointer;
              background: colors.$mint-bg;
              font-size: fn.rem(20);
              @include mixins.flex_alignEnd;
              @include mixins.flex_justifyEnd;
              outline: 1px solid colors.$mint-alt;
              border-radius: 99999vw;
              transition: var.$transition;

              .dropDownBtnInactive {
                transform: rotateX(0);
                transition: var.$transition;
              }

              &:hover {
                outline: 1px solid colors.$mint;
              }

              .dropDownBtnActive {
                transform: rotateX(180deg);
                transition: var.$transition;
              }

              .dropDownBtn {
                color: colors.$mint;
                font-size: fn.rem(24);
              }
            }
          }
        }
      }
    }
  }

  .plansMenuBtn {
    font-size: fn.rem(20);
    transition: var.$transition;
  }

  .dropDownBtnInactive {
    color: colors.$base-text;
    transform: rotateX(0);
    transition: var.$transition;
  }

  .plansDropDownBtnActive {
    color: colors.$mint;
    transform: rotateX(180deg);
    transition: var.$transition;
  }

  .plansDropDownMenuLinks,
  .dropDownMenuLinks {
    background: colors.$light;
    color: colors.$base-text;
    @include mixins.flex_column;
    border-radius: var.$borderRadiusSm;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: var.$transition;

    .dropDownMenuItem {
      width: 100%;
      padding-block: fn.rem(8);
      padding-inline: fn.rem(20);
      @include mixins.flex_centered;
      transition: var.$transition;

      .dropDownMenuLink {
        color: inherit;
        transition: var.$nav-transition;
      }

      &:hover {
        background: colors.$brandColor;

        .dropDownMenuLink {
          color: colors.$light;
        }
      }
    }

    .activePlanType {
      background: colors.$mint;
      color: colors.$light;
    }
  }

  .plansDropDownMenuActive {
    position: absolute;
    top: 25%;
    // bottom: 54.5%;
    // left: 34%;
    z-index: 1;
    transition: var.$transition;

    // SCREENS 1025px and LARGER
    @include screens.breakpoint-up(md) {
      // bottom: 56.5%;
      // left: 42%;
      // bottom: -80%;
      // left: 31%;
      transition: var.$transition;
    }

    // SCREENS 1025px and LARGER
    @include screens.breakpoint-up(lg) {
      top: 75%;
      // bottom: -180%;
      // left: 26.5%;
      // bottom: -80%;
      // left: 29%;
      transition: var.$transition;
    }

    // SCREENS 1025px and LARGER
    @include screens.breakpoint-up(xl) {
      // left: 22%;
      transition: var.$transition;
    }

    @include screens.breakpoint-up(xxl) {
      // left: 21%;
      transition: var.$transition;
    }
  }
  // .plansDropDownMenuActive {
  //   position: absolute;
  //   bottom: 54.5%;
  //   left: 34%;
  //   z-index: 1;
  //   transition: var.$transition;

  //   // SCREENS 1025px and LARGER
  //   @include screens.breakpoint-up(md) {
  //     bottom: 58.5%;
  //     left: 42%;
  //     transition: var.$transition;
  //   }

  //   // SCREENS 1025px and LARGER
  //   @include screens.breakpoint-up(lg) {
  //     bottom: -200%;
  //     left: 26.5%;
  //     transition: var.$transition;
  //   }

  //   // SCREENS 1025px and LARGER
  //   @include screens.breakpoint-up(xl) {
  //     left: 28.5%;
  //     transition: var.$transition;
  //   }
  // }

  .dropDownMenuActive {
    position: absolute;
    bottom: 12%;
    right: 25%;

    // SCREENS 1025px and LARGER
    @include screens.breakpoint-up(md) {
      // bottom: 12%;
      right: 38%;
    }

    // SCREENS 1025px and LARGER
    @include screens.breakpoint-up(lg) {
      bottom: -150%;
      right: 5.5%;
      transition: var.$transition;
    }
  }

  .displayNone {
    display: none;
    opacity: 0;
    z-index: -1;
    bottom: 100%;
    transition: var.$transition;
  }
}
